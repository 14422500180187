*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.sidebar-content{
    display: flex;
}

.sidebar-content::-webkit-scrollbar{
    display: none!important;
}

.sidebar-content-padding{
    background-color: rgba(19,57,94,0.1);
    border-radius: 8px;
    padding: 6px;
    padding-bottom: 0px;
    width: 100%;
    height: calc(100vh - 70px);
    overflow: auto;
}

.sidebar-content-padding::-webkit-scrollbar{
    width: 0px!important;
}

::-webkit-scrollbar{
    display: none;
}

.main-height{
    height: 100vh;
    background-color: white;
}

.inactive-row {
    background-color: #ffc0c091 /* Light red color */
  }
.cellContent{
    word-wrap: break-word !important;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}