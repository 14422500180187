.main-header{
    padding: 15px 30px 10px 18px;
    display: flex;
    align-items: center;
    gap: 1.7rem;
}
.logo-text{
    display: inline-flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
}
.logo-text .text {
    font-size: 1.5rem;
    font-weight: 600;
    letter-spacing: 3px;
    color:#25396f;
}

*{
    font-family: 'Poppins', sans-serif;
}

.btn-bg{
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 3px;
    background: rgba(19,57,94,0.1);
    cursor: pointer;
}
