.menu_text{
    font-weight: 500;
    color:#25396f;
    font-size: 16px;
    line-height: 23px;
    margin: 8px 0;
    letter-spacing: 0.3px;
}   

.menu_text > a {
    gap: 5px;
    padding: 10px 15px !important;
    border-radius: 8px;
    white-space: break-word;
}
    
.sidebar_height{    
    max-height:calc(100vh - 76.7px) ;
    padding-top: 0.7rem;
    overflow-x: unset;
    overflow-y: scroll;
}

.op{
    margin: 2px 0;
}

@media (max-width: 1024px) {
    .menu-text.op a{
        border-radius: 0px!important;
    }
}

.searchicon{
    transform: rotate(85deg);
    font-size: 12px;
    color: #25396f;
}
